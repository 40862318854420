.mainskill h1{

font-family: 'Courier New', Courier, monospace;
font-size: 1vw;
font-weight: 900;
position:relative;
z-index: 10;
background-color: transparent;
opacity: .8;
color: black;
-webkit-text-stroke: 0px white;
}
.mainskill h1::before{
    content: attr(data-text);
    position: absolute;
    top: 0;
    left: 0;
    
    width: 100%;
    z-index: 99;
    height: 0%;
    color:white;
    -webkit-text-stroke: 0px black;
    border-bottom: 0px solid black;
    overflow: hidden;
    animation: animate 2.5s linear ;
}
@media (max-width:400px) {
    
    .mainskill h1{
        font-size:3vw;
    }
   
}
@keyframes animate
{
    50%{
        height:100%;
    }
    100%{
        height:0%;
    }
}
.glass{
    background: linear-gradient(135deg, rgba(255, 255, 255, 0.212), rgba(255, 255, 255, 0));
    backdrop-filter: blur(20px);
    -webkit-backdrop-filter: blur(10px);
    border-radius: 20px;
    border:1px solid rgba(255, 255, 255, 0.18);
    box-shadow: 0 8px 32px 0 rgba(248, 236, 236, 0.188);
}



.glass:after {
    animation: grain 8s steps(10) infinite;
    background-image: url("https://img.freepik.com/free-photo/black-plain-concrete-textured-background_53876-160644.jpg?w=1380&t=st=1692033958~exp=1692034558~hmac=f3159b095fb5593ad17a7adf36ae6f4db4cf6030c8f4f77f4f3a3ccfe3a3cf76");
    content: "";
    height: 300%;
    left: -50%;
    opacity: 0.15;
    position: absolute;
    top: -100%;
    width: 300%;
    z-index: -9;
  }
  @keyframes grain {
    0%, 100% { transform:translate(0, 0) }
    10% { transform:translate(-5%, -10%) }
    20% { transform:translate(-15%, 5%) }
    30% { transform:translate(7%, -25%) }
    40% { transform:translate(-5%, 25%) }
    50% { transform:translate(-15%, 10%) }
    60% { transform:translate(15%, 0%) }
    70% { transform:translate(0%, 15%) }
    80% { transform:translate(3%, 35%) }
    90% { transform:translate(-10%, 10%) }
  }
  



.glass2{
    background: linear-gradient(135deg, rgba(255, 255, 255, 0.085), rgba(255, 255, 255, 0));
    backdrop-filter: blur(20px);
    -webkit-backdrop-filter: blur(10px);
  padding: 10px;
    border:1px solid rgba(255, 255, 255, 0.18);
    box-shadow: 0 8px 32px 0 rgba(29, 29, 29, 0.37);
    bottom: -6.2vh;
}
.glass2 i{
    text-shadow: 2px 2px 20px rgba(255, 255, 255, 0.242);
    -webkit-text-stroke: 1px white;
}
@media (max-width:400px) {
    .maindiv{
        border-radius: 10px;
        width: 50vw;
        height: 50vw;
    }
    .maindiv img{
        width: 50vw;
    }
    .sideHeading{
        font-size: 10vw;
        margin-bottom: 10vw;
    }
    .maindiv h2{
        font-size: 5vw;
    }
    .glass2 i{
        font-size: 6vw;
    }
    .allthecontent{
        margin-top:12vw;
       
    }
    .glass2 img{
        width: 10vw;
    }
}