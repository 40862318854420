.menu{z-index: 9999;
position: fixed;
background-color: #a3a3a3;
left: 0px;
top: 0;}
.menunav{
    color: black;
    width: full;
    position: fixed;
    z-index: 999999;
    top: 3%;
    background-color: #a3a3a3;

}
.menunav h1{
    background-color: #a3a3a3;
}
.mobilemenu{
    display: none;
}
@media (max-width: 400px){
    .mobilemenu{
        display:block;
    }
    .menunav{
        padding-bottom: 15px;
    }
    .foot{
        font-weight: 900;
   
    }
    .mainfoot{
        display: flex;
        padding: 20px 25px;
    height: fit-content;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    }
  
}