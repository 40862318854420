.cursor-pointerr{
    cursor:pointer;
}
.largemenuHeading{
   
    position: relative;
    padding-bottom: 10px;
  }
  
  .largemenuHeading::before{
    position: absolute;
    bottom: 0px;
    left: 0px;
    content: "";
    width: 0%;
    height: 1px;
    background-color: white;
    transition: width 0.6s ease-out;
    
  }
  .largemenuHeading:hover.largemenuHeading::before{
    width: 100%;
  }