
*{
  font-family: "General Sans";
  background-color: black;
  cursor: default;
}


.dot{
  z-index: 999999;
  transition: all cubic-bezier(0.19, 1, 0.22, 1) 1s;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 5px;
  font-weight: bold;
  
}

.cursor-pointerr{
  cursor: pointer;
}

.nav{
  width:100%;
  height:90px;
  display: flex;
  justify-content: space-between;
  padding: 20px 40px;
  position: fixed;
  top:0%;
  z-index: 99;
  font-weight: 900;
}
.nav a{
  color: white;


}
.nav h1{
  margin-right: 30px;
  background-color: transparent;
}
#heading{
  color:white;
  text-transform: uppercase;

  margin-top: 190px;
}
#heading h1{
  margin-left: 30px;
  font-size: 9vw;
  line-height: 1;
  font-weight: 900;
  opacity: .6;
}
#secondh1main h5{
  text-align: right;
 font-weight: 900;
  
  
}
#heading #secondh1main{
  width:fit-content;
 
}
#heading #secondh1{
  margin-left:20vw;
}
.nav h1{
font-weight: 900;
}
 .hero{
  position: relative;
  width:100%;
  height:100vh;
  background-color: black;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@media (max-width:400px) {
  .resumediv i{
    font-size: 2.8vw;
  }
}

#chotiheadings{
  color:white;
  margin-top: 7%;
  text-align: right;
  padding-right:3%;
  text-transform: uppercase;
  
}
#chotiheadings h5{
  font-size: .85vw;
  margin-top:10px;
  font-weight: 900;
}
#herofooter{
  position: absolute;
  font-size: 1.3vw;
  bottom: 23%;
  opacity: 1;
  
}
#second{
  width: 100%;
  height:auto;
  background-color: black;
  padding-top: 200px;
  padding-left: 10px;
  z-index: 3;
}
#elem img{
  height: 130%;
  width:fit-content;
  display: block;
  opacity: 0;
  
}
#elem{
  height: 30%;
  width:96%;
  border-top: 1px solid white;
 color:white;
 padding:2vw 1vw ;
 display: flex;
 justify-content: space-between;
 align-items: center;
 
}
#elem h1{
  font-size: 8.6vw;
  opacity: .7;
  background-color: transparent;
}
#elem h5{
opacity: .6;
}
 .lastline{
  border-bottom: 1px solid white;
 z-index: 9;
}
#about{
  width: 100%;
  padding: 12vw 5vw;
  background-color: black;
  padding-left: 40vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 50px;
 
}
.ivg{
  

  max-width: 20vmax;
  height: auto;
 
}
.abouttext{
  width: 60vw;
 font-size: 1.125rem;
 line-height: 28px;
 
}
.abouttext h1{
  opacity: .6;
}
.abouttext .button{
  border: 2px solid white;
  width: fit-content;
  padding: 2px 22px;
  font-size: .8vw;
  border-radius: 100px;
}
.bp{
  padding-top:30px;
}
.links{
  gap:5vw;
  font-size: 15px;
}
.footer{
  padding: 2px 50px;
  
  margin-bottom: 10px;
}
.nav{
  margin-top: 0px;
   background-color: black;
    backdrop-filter: blur(20px);
    -webkit-backdrop-filter: blur(10px);
}
















@media (max-width: 400px) {
  .dot{
    display: none;
  }
  .nav h1{
margin-right: 0px;
  }
  #heading h1{
    display:block;
    font-size: 17vw;
    margin-left: 10px;
  }
  #heading #secondh1{
   margin-left: 4%;
  }
  #heading #secondh1main{
    width:auto;
   }
   .nav h1{padding-right:10px;
  padding-top: 10px;
  }
   .footer{
    display: block;
   }
   #chotiheadings h5{
    font-size: 3vw;
   }
   #chotiheadings{
    margin-top: 20%;
   }
   #herofooter{
    bottom:72vw;
   }
   #herofooter button{
   font-size: 4vw;
   }
   #second {
    width: 100%;
    height: 60vh;
    background-color: black;
    padding-left: 10px;
    padding-top: 0%;
    z-index: 3;
}
#elem h1 {
  font-size: 13.6vw;
  opacity: .7;
}
#about{
  flex-direction: column;
  padding-left: 4vw;
 
}
.ivg{
  max-width: 50vmax;
}

#about img {
  max-width:100%;
  height: auto;
  padding-left: 8%;
}
#about p{
  font-size: 4vw;
  line-height: 25px;
  width: 67vw;
}
.abouttext .button{
  font-size: 4vw;
}
.footerleft{
 
    margin-top: 0%;
    position: relative;
    top: 20px;

}
.links {
  gap: 5vw;
  font-size: 15px;
  position: relative;
  top: -13vw;
}
.footer{
  margin-top: 20vw;
}
}
@media (max-height:460px){
  #heading{
    margin-top: 80px;
  }
  #chotiheadings h5 {
    font-size: 1.4vw;
    
    font-weight: 900;
    line-height: .7;
}
#chotiheadings{
  margin-top: 3%;
}
#herofooter .downloadtext button{
  font-size: 1.8vw;
}
.resumediv i{
  font-size: 1.8vw;
}
#about{
  flex-direction: column;
  padding-left: 10vw;
}
.ivg{
  max-width: 50vmax;
}

.bp .button{
  font-size: 1.8vw;
}
}
.bp .button{
  font-size: 1.2vw;
}

.menuplus{
  background-color: transparent;
  position: relative;
  padding-bottom: 10px;
}

.menuplus::before{
  position: absolute;
  bottom: 0px;
  left: 0px;
  content: "";
  width: 0%;
  height: 1px;
  background-color: white;
  transition: width 0.4s ease-out;
  
}
.menuplus:hover.menuplus::before{
  width: 100%;
}
.lastline{
  overflow: hidden;
}


  @keyframes grain {
    0%, 100% { transform:translate(0, 0) }
    10% { transform:translate(-5%, -10%) }
    20% { transform:translate(-15%, 5%) }
    30% { transform:translate(7%, -25%) }
    40% { transform:translate(-5%, 25%) }
    50% { transform:translate(-15%, 10%) }
    60% { transform:translate(15%, 0%) }
    70% { transform:translate(0%, 15%) }
    80% { transform:translate(3%, 35%) }
    90% { transform:translate(-10%, 10%) }
  }