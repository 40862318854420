*{
    font-family: "General Sans";
  background-color: black;
  cursor: default;
}
.project{
  margin-top: 0px;
}
.project .heading{
    font-size: 6vw;
    font-weight: 900;
    opacity: .6;
}
.nav{
    z-index:9999;
}
.project1{
    margin: 60px ;
    padding:4vw 20px;
}
.subpart{
    width: 100vw;
    height: fit-content;
    padding: 5% 2%;
   
}
.subpart div{display: flex;
    padding: 1% 1%;
    width: 50vw;
    height: fit-content;
}
.bp{
    padding-top:30px;
  }
.bp  .button{
    border: 2px solid white;
    width: fit-content;
    padding: 2px 22px;
    font-size: .8vw;
    border-radius: 100px;
  }

.nextprojectdiv{
    width: 100vw;
    background-color: black;
    height: 15vw;
}

  #elem{ margin-top: 0px;
    height: fit-content;
    width:96%;
    border-top: 1px solid white;
   color:white;
   padding:2vw 1vw ;
   display: flex;
   justify-content: space-between;
   align-items: center;

  }
  .videodiv{
    margin-top:90px;
    width: 100vw;
    background-color: white;
    height: 20vw;
  position: relative;
}
  .cand{position: absolute;
    top: -5vw;
    
    opacity: 1;
    width: fit-content;
    background-color: transparent;
    
}
  .m h1{
    font-size: 1.5vw;
    font-weight: 600;
    opacity: .7;
  }

/* for small screen*/
@media (max-width:400px) {
  .videodiv .cand{
    right: 7%;
  }
    .subpart{
        flex-direction: column;
        gap: 4;
        justify-content: space-between;
    }
    .subpart div h1{
        font-size: medium;
        width: 90vw;
    }
    .bp .button{
        font-size: 3.8vw;
    }
    .cand{
      right: 7%;
    }
    .videodiv{
      height: 40vw;
    }
    .project .heading{
      line-height: 1.2;
    }
    
    .key{
      flex-wrap: wrap;
    }
    .key div{
      width: 97%;
    }
    
}
